import $ from "jquery";

import initEvents from "~/javascripts/analytics/events";
import initExternalLinks from "~/javascripts/analytics/external_links";
import initYoutube from "~/javascripts/analytics/youtube";

$(function () {
  initEvents();
  initExternalLinks();
  initYoutube();
});
