import $ from "jquery";
import Analytics from "~/src/utils/Analytics";

var player; // Used by the youtube api

function init() {
  $('iframe[src*="youtube.com/embed"]').each(function () {
    // Only track the iframe once
    if ($(this).attr("id") == null) {
      const youtubeId = $(this)
        .attr("src")
        .match(/embed\/([A-Za-z0-9_-]+)/)[1];

      $(this).attr("id", youtubeId);
      $(this).attr("src", $(this).attr("src") + "?rel=0&enablejsapi=1");
      onYouTubeIframeAPIReady($(this).attr("id"));
    }
  });
}

// Use Youtube api YT object to add event listeners inside the iframe
// The YT is initialized here 'app/views/shared/_head.html.haml' as it needs to be done early on in the page load
function onYouTubeIframeAPIReady(iframeId) {
  player = new YT.Player(iframeId, {
    events: {
      onStateChange: onPlayerStateChange,
    },
  });
}

function onPlayerStateChange(eventCode) {
  if (
    eventCode.data == YT.PlayerState.PLAYING ||
    eventCode.data == YT.PlayerState.PAUSED ||
    eventCode.data == YT.PlayerState.ENDED
  ) {
    var eventType = Object.keys(YT.PlayerState).find(function (key) {
      return YT.PlayerState[key] === eventCode.data;
    });
    var videoTitle = player.getVideoData().title;
    googleAnalyticsTransaction(eventType, videoTitle);
  }
}

function googleAnalyticsTransaction(action, videoTitle) {
  Analytics.event(action, "Youtube Video", videoTitle);
}

export default init;
