import $ from "jquery";
import Analytics from "~/src/utils/Analytics.js";

function init() {
  globalNavEvents();
  setupFormTracking();
}

// Event methods
function globalNavEvents() {
  $(".nav__menu li:not(.has-dropdown) a").each(function (key, value) {
    $(value).on("click", function () {
      Analytics.event("click", "GlobalNav", $(value).text());
    });
  });
}

function setupFormTracking() {
  $("form").each(function (e, elem) {
    $(elem).on("submit", function (form) {
      if (form.target.action.endsWith("/records")) {
        Analytics.event(
          "Search Box",
          "Search",
          $(form.target).find("input").filter(":visible:first").val()
        );
      }
    });
  });
}

export default init;
